





















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from 'vuex';
import ShowLoading from "@/components/panel/ShowLoading.vue";
import { RedCapital, SelectedCountry, Configuration, TitleTemplatePublic } from '@/configuration';
import API from '@/api';

@Component({
  computed: {...mapState(['user', 'token', 'business', 'routes'])},
  metaInfo: {
    title: 'Queremos conocerte',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data() {
    return {
      show: false,
      dialog: false,
      valid: true,
      errorMessage: null,
      selectRules: [
        (v: any) => !!v || 'Ingrese un valor'
      ],
      selected: {
        user_id: this.$store.state.user.id,
        first: this.$store.state.routes.from == '/bienvenido' ? 'Entre $4 y $10 Millones' : null,
        second: null,
        third: null,
        fourth: null,
        fifth: null,
        sixth: [],
        seventh: null,
        eighth: null,
        nineth: null,
        tenth: null,
        eleventh: null
      },
      howMuch: null,
      anualSales: null,
      nextYear: null,
      ifYouHave: null,
      debts: null,
      toWho: null,
      houseDebt: null,
      amountDebt: null,
      ifGetCredit: null,
      ifGetGifted: null,
      dreamingCredit: null,
    }
  },
  async beforeMount() {
    // this.$data.howMuch = await API.accion.eleven.howMuch()
    // this.$data.anualSales = await API.accion.eleven.anualSales()
    // this.$data.nextYear = await API.accion.eleven.nextYear()
    // this.$data.ifYouHave = await API.accion.eleven.ifYouHave()
    // this.$data.debts = await API.accion.eleven.debts()
    // this.$data.toWho = await API.accion.eleven.toWho()
    // this.$data.houseDebt = await API.accion.eleven.houseDebt()
    // this.$data.amountDebt = await API.accion.eleven.amountDebt()
    // this.$data.ifGetCredit = await API.accion.eleven.ifGetCredit()
    // this.$data.ifGetGifted = await API.accion.eleven.ifGetGifted()
    // this.$data.dreamingCredit = await API.accion.eleven.dreamingCredit()

    this.$data.show = true
  },
  methods: {
    async validate() {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.$data.errorMessage = null
        API.accion.eleven.submitQuestions(this.$data.selected.first, this.$data.selected.second,this.$data.selected.third,this.$data.selected.fourth, this.$data.selected.fifth, this.$data.selected.seventh, this.$data.selected.eighth, this.$data.selected.nineth, this.$data.selected.tenth, this.$data.selected.eleventh).then( (res: any) => {
          this.$data.dialog = true
          if(this.$data.selected.first != 'Entre $0 y $4 Millones') {
            this.$store.commit('setQuestionId', res.id)
            if(this.$store.state.business != null) {
              setTimeout(() => {
                this.$router.push({
                  path: '/solicitud-credito'
                })
              }, Configuration.Redirection.timeout)
            } else {
              setTimeout(() => {
                this.$router.push({
                  path: '/crear-empresa'
                })
              }, Configuration.Redirection.timeout)
            }

          } else {
            setTimeout(() => {
              this.$router.push({
                path: '/lo-sentimos'
              })
            }, Configuration.Redirection.timeout)
          }
        }).catch( (error: any) => {
          this.$data.errorMessage = error.response.data.message
        })
      }
    }
  },
  components: {
		ShowLoading
	}
})

export default class MeetYou extends Vue {}
